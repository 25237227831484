<template>
  <div class="game-container">
    <DragToCakeGame
      :bgImgList="bgImgList"
      :bgImg="bgImg"
      :optionImgList="optionImgList"
      :lessonNO="332"
    />
  </div>
</template>

<script>
import DragToCakeGame from "@/components/Course/GamePage/DragToCakeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-2/background.svg"),
      bgImgList: [
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-2/bear-done.svg"),
          id: 1,
          index: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-2/cow-done.svg"),
          id: 2,
          index: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-2/deer-done.svg"),
          id: 3,
          index: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-2/mouse-done.svg"),
          id: 4,
          index: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-2/panda-done.svg"),
          id: 5,
          index: 5,
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-2/rabbit-done.svg"),
          id: 6,
          index: 6,
        },
       
      ],
      optionImgList: [
        {
          id: 1,
          index: 1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-2/bear.svg"),
        },
        {
          id: 2,
          index: 2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-2/cow.svg"),
        },
        {
          id: 3,
          index: 3,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-2/deer.svg"),
        },
        {
          id: 4,
          index: 4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-2/mouse.svg"),
        },
         {
          id: 5,
          index: 5,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-2/panda.svg"),
        },
         {
          id: 6,
          index: 6,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-2/rabbit.svg"),
        },
       
      ],
     
    };
  },
  components: {
    DragToCakeGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











