<template>
  <div class="game-container">
    <DragToDressGame
      :bgImgList="bgImgList"
      :bgImg="bgImg"
      :optionImgList="optionImgList"
      :hasStar="true"
      :titleInfo="titleInfo"
      :lessonNO="33"
    />
  </div>
</template>

<script>
import DragToDressGame from "@/components/Course/GamePage/DragToDressGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-1/background.svg"),
      bgImgList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-1/owl-ears-done.svg"),
          id: 1,
          index: 1,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-1/owl-eyes-done.svg"),
          id: 2,
          index: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-1/rabbit-ears-done.svg"),
          id: 3,
          index: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-1/rabbit-eyes-done.svg"),
          id: 4,
          index: 4,
        },
       
      ],
      optionImgList: [
        {
          id: 1,
          index: 1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-1/owl-ears.svg"),
        },
        {
          id: 2,
          index: 2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-1/owl-eyes.svg"),
        },
        {
          id: 3,
          index: 3,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-1/rabbit-ears.svg"),
        },
        {
          id: 4,
          index: 4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-33-1/rabbit-eyes.svg"),
        },
       
      ],
      titleInfo:{
        pinyin:"Zhè shì shéi de?",
        hanzi:"这是谁的？"
      }
    };
  },
  components: {
    DragToDressGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











