<template>
  <div class="game-container">
    <RecognitionPinyinGame :optionList="optionList" :imgList="imgList" />
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      optionList: [
        
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-33/image-1-da.svg"),
        },
        
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-33/image-2-xiao.svg"),
        },
        
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-33/image-3-da.svg"),
        },
        
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-33/image-4-xiao.svg"),
        },
        
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-33/image-5-da.svg"),
        },
       
       
      ],

      imgList: [
        {
          id: 1,
          pinying: "dà",
          hanzi: "大",
        },
        {
          id: 2,
          pinying: "xiǎo",
          hanzi: "小",
        },
      ],
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











