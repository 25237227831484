<template>
  <div class="page-container">
    <DrawingPage :bgImg="bgImg" :lessonNo="33"/>
  </div>
</template>

<script>
import DrawingPage from "@/components/Course/CoursePage/DrawingPage";
export default {
  data() {
    return {
      bgImg:require("@/assets/img/16-GAMES/G06-drawing/lesson-33/background-camera.svg")
    };
  },
  components: {
    DrawingPage,
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  height: 100%;
}
</style>
