<template>
  <div class="wordscard-container layout-border">
    <WordCards
      :wordCardList="wordCardList"
      @changeDisplay="handleChangeDisplay"
      :lessonNum="11"
    />
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: "redColor",
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "yǎnjing",
          hanzi: "眼睛",
          english: "eye(s)",
          image: require(`@/assets/img/12-Vocabulary/lesson-33-yanjing-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "dà",
          hanzi: "大",
          english: "big",
          image: require(`@/assets/img/12-Vocabulary/lesson-33-da-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "five",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "ěrduǒ",
          hanzi: "耳朵",
          english: "ear(s)",
          image: require(`@/assets/img/12-Vocabulary/lesson-33-erduo-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "xiǎo",
          hanzi: "小",
          english: "small",
          image: require(`@/assets/img/12-Vocabulary/lesson-33-xiao-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "...dà/xiǎo",
          hanzi: "……大/小",
          english: "small",
          image: require(`@/assets/img/12-Vocabulary/lesson-33-da-xiao.svg`),
          blue: true,
        },
       
      ],
    };
  },
  methods: {
    handleChangeDisplay(index, isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
