<template>
  <div class="game-container">
    <DrawingGroupPage
      :bgImgList="bgImgList"
      :buttonList="buttonList"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import DrawingGroupPage from "@/components/Course/CoursePage/DrawingGroupPage";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: "huà yǎnjing",
        hanzi: "画眼睛",
      },
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-33/menu-1.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-33/menu-2.svg"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-33/menu-3.svg"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-33/menu-4.svg"),
          isCircle: true,
          startNumberRange: 4,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-33/menu-5.svg"),
          isCircle: true,
          startNumberRange: 5,
          endNumberRange: 5,
        },
      ],
      bgImgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-33/image-1.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-33/image-2.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-33/image-3.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-33/image-4.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-33/image-5.svg"),
        },
      ],
    };
  },
  components: {
    DrawingGroupPage,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
